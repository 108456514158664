<script>
import { getUserPlaintext } from '@/api/Common'

export default {
  name: 'CiphertextViewer',
  props: {
    userId: { type: [Number, String], default: '' },
    data: { type: Object, default: () => Object.assign({}) },
    field: { type: String, default: '' }
  },
  computed: {
    ciphertext() { // 密文
      return this.data?.[this.field]
    },
    plaintext() { // 明文
      return this.info?.[this.field]
    }
  },
  data() {
    return {
      loading: false,
      shown: false, // 是否显示明文

      info: {}
    }
  },
  methods: {
    async getPlaintext() {
      if(!this.userId) {
        return
      }
      this.loading = true

      const data = await getUserPlaintext({ user_id: this.userId }).catch(() => '')

      this.loading = false

      if(!data || data.error_code) {
        this.$message.error('获取信息失败')
        return
      }

      this.info = data?.data || {}
    },

    async showPlainText(shown) {
      if(!shown) {
        this.shown = shown
      }

      if(shown) {
        if(!this.plaintext) {
          await this.getPlaintext()
        }

        this.shown = shown
      }
    }
  }
}
</script>

<template>
  <div class="flex">
    <span v-if="!shown" class="text">{{ ciphertext || '--' }}</span>
    <span v-else class="text">{{ plaintext || '--' }}</span>

    <a-spin :spinning="loading">
      <a-icon v-if="!shown" type="eye" class="icon pointer" @click="showPlainText(true)" />
      <a-icon v-else type="eye-invisible" class="icon pointer" @click="showPlainText(false)" />
    </a-spin>
  </div>
</template>

<style scoped lang="scss">
.flex {
  width: 100%;
}

.text {
  word-break: break-all;
}

.icon {
  margin-left: 6px;
}
</style>
